import { useRouter } from "vue-router";
import { useStore } from "vuex";

function useExamRoutes() {
  const store = useStore();
  const router = useRouter();

  const examPatientRoute = (flow: "image" | "laboratory") => {
    const query: any = {};
    Object.keys(store.state.exam.form)
      .map((cd_estabelecimento) => Number(cd_estabelecimento))
      .forEach((cd_estabelecimento) => {
        query[`et${cd_estabelecimento}`] = [
          ...store.state.exam.form[cd_estabelecimento].cd_procedimento.map((_id) => `pe${_id}`),
        ];
      });

    return { name: `exam-${flow}-confirm`, query, params: { noScroll: "true" } };
  };

  function goToExamPatient(flow: "image" | "laboratory" = "laboratory") {
    const routeTo = { ...examPatientRoute(flow) };
    router.push(routeTo);
  }

  return { goToExamPatient, examPatientRoute };
}

export { useExamRoutes };
